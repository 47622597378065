import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { PersonExamService } from '../../views/persons/services/person-exams.service';
import { UtilsService } from '../../shared/services/utils.service';
import { DateService } from '../../shared/services/date.service';
import { PersonEntityService } from './person-entity.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Exams, PerformedExam, PerformedExamAuds, PerformedExamAudFormatted, PerformedExamAudEntityFormatted } from '../models/exams';

@Injectable({
  providedIn: 'root',
})
export class PerformedExamsEntityService extends BaseEntityService<PerformedExam> {
  constructor(
    private personExamService: PersonExamService,
    private utilsService: UtilsService,
    private personEntityService: PersonEntityService,
    private dateService: DateService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService
  ) {
    super(httpClient, environment.apiUrl, {
      singular: 'performedExam',
      plural: 'performedExams',
      url: 'performed-exams',
    }, routerParamsService);
    this.nameId = 'performedExamId';
  }

  save(dataToSave: any): Observable<any> {
    const bodyMany = this.createManyForms(dataToSave);
    return bodyMany[0]?.id ? this.update(bodyMany[0]) : this.createMany(bodyMany);
  }

  private createMany(bodyMany: PerformedExam[]): Observable<PerformedExam[]> {
    return this.httpClient.post(`${this.entityUrl}/create-many`, { performedExams: bodyMany }).pipe(
      map((response: any) => response?.performedExams || [])
    );
  }

  getPerformedExamHome(queryParams: any): Observable<PerformedExam[]> {
    return this.httpClient.get(this.entityUrl, { params: queryParams }).pipe(
      map((response: any) => response?.performedExams || [])
    );
  }

  createManyForms(dataToSave: any): PerformedExam[] {
    const { defaultValues, formConfigs, formConfigDefault } = dataToSave;
    return formConfigs.map(({ exam, form }) => {
      const values = { ...defaultValues, ...formConfigDefault.form.value, ...form.value };
      Object.keys(values).forEach(key => !values[key] && delete values[key]);

      const examResults = exam.expectedExamResults
        .map(({ name, id }) => values[name] ? { value: values[name], expectedExamResult: { id } } : null)
        .filter(Boolean);
      exam.expectedExamResults.forEach(({ name }) => delete values[name]);

      return {
        ...values,
        performedAt: this.dateService.format(values[`performedAt${exam.id}`] ?? values.performedAt, 'dateLocal', true, 'date'),
        report: values[`report${exam.id}`] ?? values.report,
        exam: { id: exam.id },
        examParse: { id: values.examParseId },
        examResults,
        laboratory: values.laboratory ?? null,
        id: values.performedExamId || null,
        documentUrl: values.documentUrl || null,
        source: 'MANUAL',
      };
    });
  }

  remove(id: any): Observable<void> {
    return this.delete(id);
  }

  audsRequest(queryParams: any): Observable<PerformedExamAuds[]> {
    return this.httpClient.get(`${environment.apiUrl}/performed-exam-auds`, { params: queryParams }).pipe(
      map((response: any) => response?.performedExamAuds || [])
    );
  }

  auds(queryParams: any): Observable<PerformedExamAudFormatted[]> {
    return this.audsRequest(queryParams).pipe(
      switchMap(response => this.populatePersons(response)),
      switchMap(response => this.populateExams(response)),
      map(response => this.createListAud(response))
    );
  }

  private populatePersons(auds: PerformedExamAuds[]): Observable<PerformedExamAuds[]> {
    const ids = [...new Set(auds.map(r => r.revision.person?.id).filter(Boolean))];
    return this.personEntityService.getWithQuery({ ids: ids.join(',') }).pipe(
      map(persons => auds.map(r => ({
        ...r,
        revision: { ...r.revision, person: persons.find(p => p.id === r.revision.person.id) }
      })))
    );
  }

  private populateExams(auds: PerformedExamAuds[]): Observable<PerformedExamAuds[]> {
    const ids = [...new Set(auds.map(r => r.entity.exam?.id).filter(Boolean))];
    return this.getExams({ ids: ids.join(',') }).pipe(
      map(exams => auds.map(r => ({
        ...r,
        entity: { ...r.entity, exam: exams.find(e => e.id === r.entity?.exam?.id) }
      })))
    );
  }

  getExams(queryParams: any): Observable<Exams[]> {
    return this.httpClient.get(`${environment.apiUrl}/exams`, { params: this.utilsService.objectParams(queryParams) }).pipe(
      map((response: any) => response?.exams || [])
    );
  }

  createListAud(auds: PerformedExamAuds[]): PerformedExamAudFormatted[] {
    auds.forEach((aud, index) => {
      aud.previousEntity = auds.slice(index + 1).find(oldAud => oldAud.entity.id === aud.entity.id)?.entity;
    });
    return auds.map(aud => this.audFormat(aud));
  }

  audFormat(aud: PerformedExamAuds): PerformedExamAudFormatted {
    return {
      examName: aud.entity.exam?.name || aud.previousEntity?.exam?.name,
      revisionPersonName: aud.revision?.person?.personProperties?.name,
      revisionTimestamp: this.dateService.formatPipe(aud.revision?.timestamp, 'DD/MM/YYYY HH:mm:ss'),
      type: aud.type,
      old: aud.previousEntity ? this.audFormatEntity(aud.previousEntity) : null,
      current: this.audFormatEntity(aud.entity),
    };
  }

  audFormatEntity(entity: PerformedExam): PerformedExamAudEntityFormatted {
    return {
      report: entity.report,
      performedAt: entity.performedAt ? this.dateService.formatPipe(entity.performedAt, 'DD/MM/YYYY') : '',
      documentUrl: entity.documentUrl,
      requestedBy: entity.requestedBy ? this.utilsService.stringFormat(entity.requestedBy) : '',
      results: entity.examResults?.map(er => {
        er.expectedExamResult = entity.exam?.expectedExamResults?.find(eer => eer.id === er.expectedExamResult?.id);
        return `${er.expectedExamResult?.name}: ${this.personExamService.formatExamResults(er)}`;
      }) || [],
    };
  }
}
